import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Self-Service Delivery Platform
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Digital Retailer Store
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        On-premise digital store for fundraising, loyalty and community building
      </p>
      <p className="mt-4 text-lg text-gray-500">
        WAIVPAY’s digistore is a white-labelled store front for digital retailer store cards.
        Embed into your website to offer your members, customers and staff the best retailer store cards and perks.
        You have complete control over stock, quantity and discount with full reporting and budget controls.
      </p>
      <p className="mt-4 text-lg text-gray-500">
        Retailer cards are offered at a discount for you to make margin on each and every sale. Fundraising has never been so simple!
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;
